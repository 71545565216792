import React, { useEffect } from 'react';
import '/client/app/styles/text.scss';

export default function AboutView() {
	useEffect(() => {
		document.title = 'pfyt - about';

		return function cleanup() {
			document.title = 'pfyt';
		};
	});

	return (
		<>
			<div className="mainTitle">about</div>
		</>
	);
}
